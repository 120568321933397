import React from 'react';
import styled from 'styled-components';
import { RadioButton } from './RadioButton';

interface IProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  roundInput?: boolean;
}

const LabelContainer = styled.label`
  width: fit-content;
`;

const LabelText = styled.span`
  margin-left: 8px;
`;

const TickCheckbox = styled.input`
  vertical-align: middle;
  transform: scale(1.5);
`;

export function Checkbox(props: IProps) {
  if (props.roundInput) {
    return <RadioButton type="checkbox" {...props} />;
  }
  return (
    <LabelContainer>
      <TickCheckbox
        type="checkbox"
        disabled={props.disabled}
        name={props.name}
        onChange={props.onChange}
        checked={props.checked}
        data-testid="radio-button"
        readOnly={props.readOnly}
      />
      <LabelText>{props.label}</LabelText>
    </LabelContainer>
  );
}
