/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
import itiriri from 'itiriri';
import {
  FacilityGroupConfigurationDto,
  HSAdministeredDose,
  HSAdministeredDrug,
  HSChartDose,
  HSChartItem,
  HSDoseRound,
  HSDrug,
  HSFacility,
  HSMedication,
  HSPackedMedication,
  HSPackedPatientDay,
  HSPackedPatientPrnMedication,
  HSPackedPrnMedication,
  HSPatchObservation,
  HSPatient,
  HSPatientProfile,
  PatientMedicationUpdateRequest,
  ReasonCode,
} from 'server-openapi';
import { DateUtils } from '../../core/utils/dateUtils';
import { isSameDay, startOfDay, subDays } from 'date-fns';
import { DrugUtils } from './DrugUtils';
import * as datefns from 'date-fns';
import { ResidentDetailsUtils } from './ResidentDetailsUtils';
import { RoundUtils, ScheduledActivity } from './RoundUtils';
import { MedisphereTestResult } from '../SyncTestResults';
import { MemoryCache } from '../../core/storage/MemoryCache';
import { MedisphereSyringeDriverActivity } from '../SyncSyringeDriverActivity';

interface PatientUtilStores {
  packedDayStore: ReadonlyMap<string, HSPackedPatientDay>;
  roundStore: ReadonlyMap<string, HSDoseRound>;
  packedPrnStore: ReadonlyMap<string, HSPackedPatientPrnMedication>;
  facilityStore: ReadonlyMap<string, HSFacility>;
  drugStore: ReadonlyMap<string, HSDrug>;
  testResultsStore: ReadonlyMap<string, MedisphereTestResult>;
  patientStore: ReadonlyMap<string, HSPatient>;
  patchObservationStore: ReadonlyMap<string, HSPatchObservation>;
  syringeDriverActivityStore: ReadonlyMap<string, MedisphereSyringeDriverActivity>;
  facilityGroupConfigurationStore: ReadonlyMap<string, FacilityGroupConfigurationDto>;
}

interface IAdministeredDrugWithDoseTimeStamp extends HSAdministeredDrug {
  doseTimestamp: string | null | undefined;
}

interface IPackedMedChangeInfo {
  medicationHasChanged: boolean;
  lastUpdatedAt: Date | undefined;
}
export class PatientUtils {
  private stores: PatientUtilStores;
  constructor(apiStores: PatientUtilStores) {
    this.stores = apiStores;
  }
  public getAdministeredDosesByRound = (selectedDate: Date, patientId: number, selectedRound?: HSDoseRound) =>
    getAdministeredDosesByRound(this.stores.roundStore, selectedDate, patientId, selectedRound);
  public getAdministeredDoses = (patientId: number) => getAdministeredDoses(this.stores.roundStore, patientId);
  public findPatientFacility = (patient: HSPatient) => findPatientFacility(this.stores.facilityStore, patient);
  public findPackedPatientDay = (patientId: number, packDate: Date, facilityId?: number) =>
    findPackedPatientDay(this.stores.packedDayStore, this.stores.patientStore, patientId, packDate, facilityId);
  public findPackedPatientPrnMedications = (patientId: number) =>
    findPackedPatientPrnMedications(this.stores.packedPrnStore, patientId);
  public findFacilityGroup = (patient: HSPatient) => findFacilityGroup(this.stores.facilityStore, patient);
  public patientHasControlledDrugs = (patient: HSPatient) =>
    patientHasControlledDrugs(
      this.stores.packedDayStore,
      patient,
      this.stores.drugStore,
      this.stores.patchObservationStore,
      this.stores.roundStore,
      this.stores.syringeDriverActivityStore,
      this.stores.facilityStore,
      this.stores.facilityGroupConfigurationStore,
      this.stores.patientStore,
    );
  public patientHasControlledPrns = (patient: HSPatient) =>
    patientHasControlledPrns(this.stores.packedPrnStore, patient, this.stores.drugStore);
  public getReasonCodesFromAdministeredDoses = (
    patient: HSPatient,
    administeredAtDate: Date,
    patientAdministeredDoses: HSAdministeredDose[],
    packedMedication?: HSPackedMedication[],
  ) => getReasonCodesFromAdministeredDoses(patient, administeredAtDate, patientAdministeredDoses, packedMedication);
  public getReasonCodesFromScheduledActivity = (scheduledActivities: ScheduledActivity[]) =>
    getReasonCodesFromScheduledActivity(scheduledActivities);
  public getTestResults = (patient: HSPatient) => getTestResults(this.stores.testResultsStore, patient);
  public getDisplayPatientName = (patient: HSPatient) => getDisplayPatientName(patient);
  public getQueryPatientString = (patient: HSPatient) => getQueryPatientString(patient);
  public getActivePatients = () => getActivePatients(this.stores.patientStore);
  public createPatientMedicationUpdateRequestObject = (
    updatedSAStatus: boolean,
    patient: HSPatient,
    packedMedication: HSPackedMedication | HSPackedPrnMedication,
    facilityGroupId: number,
  ) => createPatientMedicationUpdateRequestObject(updatedSAStatus, patient, packedMedication, facilityGroupId);
  public updatePatientMedicationSAStatus = async (
    patientMemoryCache: MemoryCache<HSPatient>,
    patient: HSPatient,
    packedMedication: HSPackedMedication | HSPackedPrnMedication,
    SAStatus: boolean,
  ) => await updatePatientMedicationSAStatus(patientMemoryCache, patient, packedMedication, SAStatus);
  public getPackedMedicationFromAdministeredDrug = (doseTimestamp: string, medicationId: number) =>
    //TODO: include packed prn store?? (not likely to include patch medication)
    getPackedMedicationFromAdministeredDrug(doseTimestamp, medicationId, this.stores.packedDayStore);
  public fetchChangedMedicationDetailsFromPatient = (patient: HSPatient) =>
    fetchChangedMedicationDetailsFromPatient(patient);
  public getHSMedicationFromPackedMedication = (
    patient: HSPatient,
    packedMedication: HSPackedMedication | HSPackedPrnMedication | undefined,
  ) => getHSMedicationFromPackedMedication(patient, packedMedication);

  public getHSChartItemFromPackedMedication = (
      patient: HSPatient,
      packedMedication: HSPackedMedication | HSPackedPrnMedication | undefined,
  ) => getHSChartItemFromPackedMedication(patient, packedMedication);

  public getHSChartDoseFromChartItem = (
      patient: HSPatient,
      chartItem: HSChartItem | undefined,
  ) => getHSChartDoseFromChartItem(patient, chartItem);


  public getHSChartDoseFromPackedMedication = (
      patient: HSPatient,
      packedMedication: HSPackedMedication | HSPackedPrnMedication | undefined,
  ) => getHSChartDoseFromPackedMedication(patient, packedMedication);

  public getHSChartDoseFromMedication = (
      patient: HSPatient,
      medication: HSMedication | undefined,
  ) => getHSChartDoseFromMedication(patient, medication);
  public getAllPatientPackedMedications = (patient: HSPatient, selectedDate: Date) =>
    getAllPatientPackedMedications(patient, selectedDate, this.stores.packedDayStore);
}

function getAdministeredDosesByRound(
  rounds: ReadonlyMap<string, HSDoseRound>,
  selectedDate: Date,
  patientId: number,
  selectedRound: HSDoseRound | undefined,
) {
  if (selectedRound) {
    return selectedRound.administeredDoses
      ? selectedRound.administeredDoses
          .filter(
            (ad) =>
              ad.patientId === patientId &&
              !!ad.doseTimestamp &&
              isSameDay(DateUtils.toDate(ad.doseTimestamp), selectedDate) &&
              ad.administeredDrugs &&
              ad.administeredDrugs.length > 0,
          )
          .sort((a, b) => a.doseTimestamp?.localeCompare(b.doseTimestamp ?? '') ?? 0)
      : [];
  }
  return getAdministeredDoses(rounds, patientId).map((v) => v.administeredDose);
}
function getAdministeredDoses(rounds: ReadonlyMap<string, HSDoseRound>, patientId: number) {
  return itiriri(rounds.values())
    .flat((r) =>
      r.administeredDoses
        ? r.administeredDoses
            .filter((ad) => ad.patientId === patientId)
            .map((ad) => ({ administeredDose: ad, doseRoundClinicalSystemId: r.clinicalSystemId! }))
        : [],
    )
    .toArray();
}

function findPackedPatientDay(
  packedPatientDays: ReadonlyMap<string, HSPackedPatientDay>,
  patientStore: ReadonlyMap<string, HSPatient>,
  patientId: number,
  packDate: Date,
  facilityId?: number,
): HSPackedPatientDay | undefined {
  const packedPatientDayOptions = itiriri(packedPatientDays.values())
    .filter(
      (p) =>
        p.patientId === patientId &&
        p.packDate !== undefined &&
        p.packDate !== null &&
        isSameDay(DateUtils.toOffsetlessDate(p.packDate), packDate),
    )
    .toArray();

  const profiles = patientStore.get(patientId.toString())?.patientProfiles ?? [];

  /* TODO: Resolve this fully. Currently this runs into issues as the packed medication on the first day of
   a patient changing facilities omits medication for which the administration time has already passed. */
  // In the case of a facility change for a patient, default to the matching facility, and otherwise take anything
  const packedDay =
    packedPatientDayOptions.find(
      (packedDay) =>
        packedDay.facilityId === facilityId && packedDay.packedMedications && packedDay.packedMedications.length > 0,
    ) ?? packedPatientDayOptions[0];
  return packedDay
    ? {
        ...packedDay,
        packedMedications: packedDay.packedMedications?.filter((packedMed) =>
          profiles.some(
            (profile) =>
              [...(profile.allCurrentMedications ?? []), ...(profile.recentlyDeletedMedications ?? [])].find(
                (med) => med.hsId === packedMed.medicationId,
              )?.showOnSigningSheet,
          ),
        ),
      }
    : undefined;
}

function findPackedPatientPrnMedications(
  packedPatientPrnMedications: ReadonlyMap<string, HSPackedPatientPrnMedication>,
  patientId: number,
) {
  return itiriri(packedPatientPrnMedications.values()).find((p) => p.patientId === patientId);
}

function findPatientFacility(facilityStore: ReadonlyMap<string, HSFacility>, patient: HSPatient) {
  return facilityStore.get(patient.facility!.toString());
}

function findFacilityGroup(facilities: ReadonlyMap<string, HSFacility>, patient: HSPatient) {
  return itiriri(facilities.values()).find((f) => f.hsId === patient.facility)!.facilityGroupId!;
}

function patientHasControlledDrugs(
  packedPatientDaysStore: ReadonlyMap<string, HSPackedPatientDay>,
  patient: HSPatient,
  drugStore: ReadonlyMap<string, HSDrug>,
  patchObservationStore: ReadonlyMap<string, HSPatchObservation>,
  roundsStore: ReadonlyMap<string, HSDoseRound>,
  activityStore: ReadonlyMap<string, MedisphereSyringeDriverActivity>,
  facilityStore: ReadonlyMap<string, HSFacility>,
  facilityGroupConfigurationStore: ReadonlyMap<string, FacilityGroupConfigurationDto>,
  patientStore: ReadonlyMap<string, HSPatient>,
) {
  const stores = {
    roundStore: roundsStore,
    patientStore: patientStore,
    facilityStore: facilityStore,
    drugStore: drugStore,
    packedDayStore: packedPatientDaysStore,
    packedPrnStore: new Map(),
    secondCheckStore: new Map(),
    nimAvailableDrugStore: new Map(),
    testResultsStore: new Map(),
    facilityGroupConfigurationStore: facilityGroupConfigurationStore,
    syringeDriverActivityStore: activityStore,
    patchObservationStore: patchObservationStore,
  };
  const residentDetailsUtils = new ResidentDetailsUtils(stores);
  const roundUtils = new RoundUtils(stores);

  const packedPatientDay = findPackedPatientDay(
    packedPatientDaysStore,
    patientStore,
    patient.hsId!,
    new Date(),
    patient.facility,
  );

  const regularContinuousMedication =
    packedPatientDay?.packedMedications &&
    residentDetailsUtils.getGroupedMedications(
      packedPatientDay.packedMedications,
      patient.patientProfiles!,
      new Date(),
      patient.hsId!,
      patient.facility!,
    );

  return !!regularContinuousMedication?.find((packedMedicationList) =>
    roundUtils.controlledDrugInRound([
      ...packedMedicationList.medications,
      ...(packedMedicationList.scheduledActivityAction?.packedMed
        ? [packedMedicationList.scheduledActivityAction.packedMed]
        : []),
    ]),
  );
}

function patientHasControlledPrns(
  packedPatientPrnStore: ReadonlyMap<string, HSPackedPatientPrnMedication>,
  patient: HSPatient,
  drugStore: ReadonlyMap<string, HSDrug>,
) {
  const packedPatientPrnMedication = itiriri(packedPatientPrnStore.values()).find((p) => p.patientId === patient.hsId);
  const drugs =
    packedPatientPrnMedication?.packedPrnMedications?.map((prn) => {
      return (prn.drugHsId ? drugStore.get(prn.drugHsId.toString()) : {}) ?? {};
    }) ?? [];
  return drugs.some((drug) => {
    return DrugUtils.getDrugWarnings(drug).controlledDrug;
  });
}

function getReasonCodesFromAdministeredDoses(
  patient: HSPatient,
  administeredAtDate: Date,
  patientAdministeredDoses: HSAdministeredDose[],
  packedMedication?: HSPackedMedication[],
) {
  const patientDayAdministeredDrugs: IAdministeredDrugWithDoseTimeStamp[] = itiriri(patientAdministeredDoses)
    .filter((ad) => !!ad.doseTimestamp && isSameDay(DateUtils.toDate(ad.doseTimestamp), administeredAtDate))
    .flat((aDose) =>
      aDose.administeredDrugs
        ? aDose.administeredDrugs.map((aDrug) => {
            return { doseTimestamp: aDose.doseTimestamp, ...aDrug };
          })
        : [],
    )
    .sort((a, b) => DateUtils.toDate(b.administeredAt!).getTime() - DateUtils.toDate(a.administeredAt!).getTime())
    .toArray()
    /*if the status for the same medication is changed, it registers as 2 different administered drugs
      this only keeps the most recent one so that we get the most recent status/reason code.
       Same drug has the same medication ID with same doseTimestamp
    */
    .filter(
      (ad, index, arr) =>
        arr.findIndex((t) => t.medicationId === ad.medicationId && t.doseTimestamp === ad.doseTimestamp) === index,
    );
  //return just mapped administered drugs if not in active round, else merge with round medication
  return !!packedMedication && packedMedication.length > 0
    ? mergePackedMedicationsWithAdministeredDrugs(packedMedication, patientDayAdministeredDrugs)
    : patientDayAdministeredDrugs.map((pad) => pad.reasonCode);
}

function mergePackedMedicationsWithAdministeredDrugs(
  packedMedication: HSPackedMedication[],
  administeredDrugs: IAdministeredDrugWithDoseTimeStamp[],
) {
  return (
    packedMedication?.map((packedMed) => {
      const scheduledDoseDateTime = new Date(packedMed.doseTimestamp!);
      return administeredDrugs.find(
        (pad) =>
          pad.medicationId === packedMed.medicationId &&
          pad.doseTimestamp &&
          datefns.isEqual(new Date(pad.doseTimestamp), scheduledDoseDateTime),
      )?.reasonCode;
    }) ?? []
  );
}

function getTestResults(testResultStore: ReadonlyMap<string, MedisphereTestResult>, patient: HSPatient) {
  return itiriri(testResultStore.values())
    .filter((t) => t.patientId === patient.hsId)
    .toArray();
}

function getDisplayPatientName(patient: HSPatient) {
  return `${patient.givenName} ${patient.familyName} ${patient.preferredName && `(${patient.preferredName})`}`;
}

function getQueryPatientString(patient: HSPatient) {
  return `${patient.givenName?.toUpperCase()} ${patient.familyName?.toUpperCase()} ${
    patient.preferredName?.toUpperCase() ?? ''
  }`;
}

function getActivePatients(patientStore: ReadonlyMap<string, HSPatient>) {
  return itiriri(patientStore.values()).filter((patient) => !patient.dischargedDate);
}

function createPatientMedicationUpdateRequestObject(
  updatedSAStatus: boolean,
  patient: HSPatient,
  packedMedication: HSPackedMedication | HSPackedPrnMedication,
  facilityGroupId: number,
): PatientMedicationUpdateRequest | undefined {
  //find the corresponding medication in patient profile from packed medication
  const patientMedication = patient.patientProfiles
    ?.flatMap((profile) => profile.allCurrentMedications)
    .find((medication) => medication?.hsId === packedMedication.medicationId);
  if (!patientMedication) {
    return undefined;
  }
  // create the request object to be sent to MRS
  return {
    patientId: patient.hsId!,
    medicationId: patientMedication.hsId!,
    medicationVersion: patientMedication.version!,
    selfAdministeredStatus: updatedSAStatus,
    facilityGroupId: facilityGroupId,
  };
}

/**
 * This is a bit tricky since we have to update the specific medication inside a particular patient profile
 * @param patientMemoryCache - cache object pointing to the indexed db storing patient data
 */
async function updatePatientMedicationSAStatus(
  patientMemoryCache: MemoryCache<HSPatient>,
  patient: HSPatient,
  packedMedication: HSPackedMedication | HSPackedPrnMedication,
  SAStatus: boolean,
) {
  //find the corresponding medication in patient profile from packed medication
  const patientMedication = patient.patientProfiles
    ?.flatMap((profile) => profile.allCurrentMedications)
    .find((medication) => medication?.hsId === packedMedication.medicationId);

  //change the self administered status
  const updatedMedication: HSMedication = {
    ...patientMedication,
    selfAdministered: SAStatus,
    lastModifiedAt: DateUtils.fromDate(new Date()),
  };

  //get the profile which contains the medication to update
  const patientProfile = patient.patientProfiles?.find(
    (profile) =>
      !!profile.allCurrentMedications?.find((medication) => medication?.hsId === packedMedication.medicationId),
  );

  //update the all current medication array to include the updated medication
  const updatedAllCurrentMedication = patientProfile?.allCurrentMedications?.map((medication) => {
    if (medication.hsId === updatedMedication.hsId) {
      return updatedMedication;
    }
    return medication;
  });

  //updated patient profile object with the array that includes the updated medication
  const updatedPatientProfile: HSPatientProfile = {
    ...patientProfile,
    allCurrentMedications: updatedAllCurrentMedication,
  };

  //updated patient profile array to be added to the new patient object
  const updatedPatientProfileArray = patient.patientProfiles?.map((profile) => {
    if (profile.hsId === updatedPatientProfile.hsId) {
      return updatedPatientProfile;
    }

    return profile;
  });

  //final updated patient object
  const updatedPatient: HSPatient = {
    ...patient,
    patientProfiles: updatedPatientProfileArray,
  };

  //update the indexed db and insert the updated patient into memory cache
  //this ensures that the patient is eager updated after the API call to reflect changes immediately
  await patientMemoryCache.set(patient.hsId!.toString(), updatedPatient);
}

function getPackedMedicationFromAdministeredDrug(
  doseTimestamp: string,
  medicationId: number,
  packedDayStore: ReadonlyMap<string, HSPackedPatientDay>,
) {
  return itiriri(packedDayStore.values())
    .toArray()
    .flatMap((ppd) => ppd.packedMedications)
    .find(
      (packedMedication) =>
        packedMedication &&
        datefns.isEqual(DateUtils.toDate(packedMedication.doseTimestamp!), DateUtils.toDate(doseTimestamp)) &&
        packedMedication.medicationId === medicationId,
    );
}

/**
 * This function is used to determine the chart review date for a patient and to check if the patient has any changed medications
 * For checking changed medications, HSMedications from patient profile are taken into account.
 * In doing this the chart page logic now works same as logic to highlight changed medications
 * @returns the most recently updated medication date and true if any medication for today have been changed
 */
function fetchChangedMedicationDetailsFromPatient(patient: HSPatient): IPackedMedChangeInfo {
  //this checks if the medication is new or lastModified in 24 hours
  const isMedChanged = !!patient.patientProfiles
    ?.flatMap((profile) => profile.allCurrentMedications ?? [])
    .find((medication) => {
      const medStatus = DrugUtils.getMedicationStatus(medication);
      return medStatus.isNew || medStatus.isChanged;
    });

  //get the most recent last modified date for a changed medication
  const latestChangedMedicationDate = patient.patientProfiles
    ?.flatMap((profile) => profile.allCurrentMedications ?? [])
    .map((medication): Date => DateUtils.toDate(medication.lastModifiedAt!))
    .reduce((lhs, rhs) => (DateUtils.compareDates(lhs, rhs) < 0 ? lhs : rhs), new Date(0));

  //get the most recent last modified date of a ceased medication
  const latestCeasedMedicationDate = patient.patientProfiles
    ?.flatMap((profile) => profile.recentlyDeletedMedications ?? [])
    .map((medication): Date => DateUtils.toDate(medication.lastModifiedAt!))
    .reduce((lhs, rhs) => (DateUtils.compareDates(lhs, rhs) < 0 ? lhs : rhs), new Date(0));

  const currentTime = Date.now();

  //patient has ceased medication if the most recent ceased date is within 24 hours
  const hasCeasedMedication = latestCeasedMedicationDate
    ? datefns.isWithinInterval(latestCeasedMedicationDate, { start: datefns.subDays(currentTime, 1), end: currentTime })
    : false;
  return {
    medicationHasChanged: isMedChanged || hasCeasedMedication,
    //compare ceased date and medication change date to determine which one is more recent
    lastUpdatedAt:
      latestCeasedMedicationDate &&
      latestChangedMedicationDate &&
      datefns.isAfter(latestCeasedMedicationDate, latestChangedMedicationDate)
        ? latestCeasedMedicationDate
        : latestChangedMedicationDate,
  };
}

function getHSMedicationFromPackedMedication(
  patient: HSPatient,
  packedMedication: HSPackedMedication | HSPackedPrnMedication | undefined,
) {
  if (!packedMedication) {
    return undefined;
  }
  return (
    patient.patientProfiles
      ?.flatMap((profile) => profile.allCurrentMedications)
      .find((medication) => medication?.hsId === packedMedication.medicationId) ?? undefined
  );
}

function getHSChartItemFromPackedMedication(patient: HSPatient, packedMedication: HSPackedMedication | HSPackedPrnMedication | undefined): HSChartItem | undefined {
  const medication = getHSMedicationFromPackedMedication(patient, packedMedication);
  return getHSChartItemFromMedication(patient, medication);
}


function getHSChartDoseFromPackedMedication(patient: HSPatient, packedMedication: HSPackedMedication | HSPackedPrnMedication | undefined): HSChartDose | undefined {
  const chartItem = getHSChartItemFromPackedMedication(patient, packedMedication);
  return getHSChartDoseFromChartItem(patient, chartItem);
}

function getHSChartDoseFromMedication(patient: HSPatient, medication: HSMedication | undefined): HSChartDose | undefined {
  const chartItem = getHSChartItemFromMedication(patient, medication);
  return getHSChartDoseFromChartItem(patient, chartItem);
}

function getHSChartItemFromMedication(patient: HSPatient, medication: HSMedication | undefined): HSChartItem | undefined {
  if (!medication || !medication.chartItemId) {
    return undefined;
  }
  // Note that the chartItemId property on the medication references the chart item version.
  // The chart item version is the primary key in the HealthStream DB. The hsId is the chain ID.
  // This is consistent with how the medications work (hsId is the ancestor tag, version is the primary key).
  return patient.chartItems?.find(chartItem => chartItem.version === medication.chartItemId);
}

function getHSChartDoseFromChartItem(patient: HSPatient, chartItem: HSChartItem | undefined): HSChartDose | undefined {
  if (!chartItem) {
    return undefined;
  }

  const now = new Date();
  return chartItem.doses?.find((d) => {
    if (d.startDate && !d.endDate) {
      return now >= new Date(d.startDate);
    } else if (d.startDate && d.endDate) {
      return now >= new Date(d.startDate) && now <= new Date(d.endDate);
    }
    return false;
  });
}



function getAllPatientPackedMedications(
  patient: HSPatient,
  selectedDate: Date,
  packedDayStore: ReadonlyMap<string, HSPackedPatientDay>,
) {
  const profiles = patient.patientProfiles ?? [];
  return itiriri(packedDayStore.values())
    .filter(
      (day) =>
        day.patientId === patient.hsId &&
        !!day.packDate &&
        subDays(startOfDay(selectedDate), 14) <= DateUtils.toOffsetlessDate(day.packDate),
    )
    .map((day) => ({
      ...day,
      packedMedications: day.packedMedications?.filter((packedMed) =>
        profiles.some(
          (profile) =>
            [...(profile.allCurrentMedications ?? []), ...(profile.recentlyDeletedMedications ?? [])].find(
              (med) => med.hsId === packedMed.medicationId,
            )?.showOnSigningSheet,
        ),
      ),
    }))
    .toArray();
}

function getReasonCodesFromScheduledActivity(scheduledActivities: ScheduledActivity[]) {
  return scheduledActivities.map((activity) => {
    if (activity.patchActivity || activity.syringeActivity) {
      return ReasonCode.Dosed;
    }
    return undefined;
  });
}
