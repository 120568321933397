import itiriri from 'itiriri';
import React from 'react';
import { HSDrug, HSPatient } from 'server-openapi';
import { Grid } from '../../../../kit/Grid';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import { MedicationInformationBox } from '../../../ResidentDetails/components/medicationInformation/MedicationInformationBox';
import styled from 'styled-components';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import {
  AdministrationPermissions
} from "../../../ResidentDetails/components/MedicationListsTabbedRouter/MedicationListsTabbedRouter";
import { MedicationGroup } from '../../../../syncstream/utils/ResidentDetailsUtils';

interface IProps {
  patients: HSPatient[];
  facilityGroupId: string;
  groupedPackedMedicationList: MedicationGroup[];
}

export function ControlledDrugs(props: IProps) {
  const services = useSyncCenter();
  const drugStore = useStore(services.drugs.store).store;

  const allDrugs = itiriri(drugStore.values()).toArray();

  return (
    <>
      <h1>Controlled drugs</h1>
      <Container>
        {props.patients.map((patient, patientIndex) => (
          <PatientControlDrugs
            key={patientIndex}
            patient={patient}
            patientIndex={patientIndex}
            allDrugs={allDrugs}
            facilityGroupId={props.facilityGroupId}
            groupedPackedMedicationList={props.groupedPackedMedicationList}
          />
        ))}
      </Container>
    </>
  );
}

interface IPatientControlDrugsProps {
  patient: HSPatient;
  patientIndex: number;
  allDrugs: HSDrug[];
  facilityGroupId: string;
  groupedPackedMedicationList: MedicationGroup[];
}

export function PatientControlDrugs(props: IPatientControlDrugsProps) {
  const roundUtils = useApiUtils().rounds;
  const roundWindow = roundUtils.getRoundWindow(new Date(), parseInt(props.facilityGroupId));

  const controlledDrugsInRound = roundUtils.getPackedMedicationsForPatient(true, props.patient.hsId!, roundWindow);

  return (
    <Grid cols={1} gap={0.5}>
      {controlledDrugsInRound.map((packedMedication, index) => (
        <div key={`${props.patientIndex}-${index}`}>
          <MedicationInfoContainer>
            <MedicationInformationBox
              key={packedMedication.hsId}
              patient={props.patient}
              facilityGroupId={parseInt(props.facilityGroupId)}
              packedMedication={packedMedication}
              drugList={props.allDrugs}
              showPatientInfo
              administrationPermissions={administrationPermissions}
              selectedDate={new Date()}
              groupedPackedMedicationList={props.groupedPackedMedicationList}
            />
          </MedicationInfoContainer>
        </div>
      ))}
    </Grid>
  );
}

// Administrations should not happen under this context
const administrationPermissions: AdministrationPermissions = {
  canCompleteRound: false,
  canCreateTestResults: false,
  canAdministerS8Medication: false,
  canAdministerControlledDrugs: false,
  canAdministerPackedControlledDrugs: false,
  packedMedsDoNotNeedSecondCheck: false,
  canAdministerMedication: false,
  canAdministerMissedMedication: false,
  canReAdministerMedication: false,
  canAdministerS4Medication: false,
  canAdministerShortCourseMedication: false,
  canAdministerPRNAndRecordItsOutcome: false,
  canAdministerNIMAndRecordItsOutcome: false,
  canAdministerNonPackedMedicationExcludingInjectable: false,
  canAdministerNonPackedMedicationIncludingInjectable: false,
  canAdministerSecondCheckMedication: false,
  canAdministerPackedMedication: false,
  canAdministerPatchMedicationExcludingS8: false,
  canAdministerPatchMedicationIncludingS8: false,
  canOperateSyringeDriver: false,
  canVewResupplyOrders: false,
  canAdministerSlidingScaleMedication: false,
  canAdministerPackedMedicationIndividually: false,
  canSetOtherDoseStatuses: false,
  canWithholdMedication: false,
  canSightPatch: false
};

const Container = styled.div`
  margin-top: 20px;
`;

const MedicationInfoContainer = styled.div`
  margin-bottom: 15px;
`;
