/* eslint-disable max-lines */
import { isToday, isWithinInterval, subDays } from 'date-fns';
import itiriri from 'itiriri';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import {
  HSAdministeredAdHocDrug,
  HSAdministeredDrug,
  HSDoseRound,
  HSDrug,
  HSPackedMedication,
  HSPackedPrnMedication,
  HSPatient,
  HSTestResult,
  ListSecondCheckSettingDto,
  NimAvailableDrugDto,
} from 'server-openapi';
import styled from 'styled-components';
import urljoin from 'url-join';
import { useCurrentUser } from '../../../../core/authn/UserProvider';
import { Layout } from '../../../../kit/Layout';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { RoundScheduleItem } from '../../../../syncstream/utils/RoundUtils';
import { IDrugDetailProps } from '../drugDetails/DrugDetailDialog';
import { DrugOutcome } from '../medicationInformation/DrugOutcomeDialog';
import { DrugAdministrationUtils } from './DrugAdministrationUtils';
import { ContinuousMedicationList } from './TabLists/ContinuousMedicationList';
import { NIMsMedicationList } from './TabLists/NIMsMedicationList';
import { PatchesMedicationList } from './TabLists/PatchesMedicationList';
import { PRNsMedicationList } from './TabLists/PRNsMedicationList';
import { ShortCoursesMedicationList } from './TabLists/ShortCoursesMedicationList';
import { SyringeDriversMedicationList } from './TabLists/SyringeDriversMedicationList';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { useRoundSchedule } from '../../../Rounds/services/RoundScheduleProvider';
import { EnqueuedDrugCreateData } from '../../../../syncstream/SyncRounds';
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";

interface IProps {
  patient: HSPatient;
  date: Date;
  facilityGroupId: number;
  openDrugDetail: (props: IDrugDetailProps) => void;
  round?: HSDoseRound;
  roundId?: string;
}

export interface AdministrationPermissions {
  canCompleteRound: boolean;
  canCreateTestResults: boolean;
  canAdministerS8Medication: boolean;
  canAdministerControlledDrugs: boolean;
  canAdministerPackedControlledDrugs: boolean;
  packedMedsDoNotNeedSecondCheck: boolean;
  canAdministerMedication: boolean;
  canAdministerMissedMedication: boolean;
  canReAdministerMedication: boolean;
  canAdministerS4Medication: boolean;
  canAdministerShortCourseMedication: boolean;
  canAdministerPRNAndRecordItsOutcome: boolean;
  canAdministerNIMAndRecordItsOutcome: boolean;
  canAdministerNonPackedMedicationExcludingInjectable: boolean;
  canAdministerNonPackedMedicationIncludingInjectable: boolean;
  canAdministerSecondCheckMedication: boolean;
  canAdministerPackedMedication: boolean;
  canAdministerPatchMedicationExcludingS8: boolean;
  canAdministerPatchMedicationIncludingS8: boolean;
  canOperateSyringeDriver: boolean;
  canAdministerSlidingScaleMedication: boolean;
  canVewResupplyOrders: boolean;
  canWithholdMedication: boolean;
  canAdministerPackedMedicationIndividually: boolean;
  canSetOtherDoseStatuses: boolean;
  canSightPatch: boolean;
}

//eslint-disable-next-line sonarjs/cognitive-complexity, max-lines-per-function
export function MedicationListsTabbedRouter(props: IProps) {
  const { patient, facilityGroupId, date, openDrugDetail, round } = props;

  const [secondCheckableMedication, setSecondCheckableMedication] = useState<SecondCheckableMedication[]>([]);

  const user = useCurrentUser();
  const { path } = useRouteMatch();
  const groupPermissions = useGroupPermissions();
  const administrationPermissions: AdministrationPermissions = {
    canCompleteRound: groupPermissions.canCompleteRounds ?? false,
    canCreateTestResults: groupPermissions.canRecordVitals ?? false,
    canAdministerS8Medication: groupPermissions.canAdministerS8Medication ?? false,
    canAdministerControlledDrugs: groupPermissions.canAdministerControlledDrugs ?? false,
    canAdministerPackedControlledDrugs: groupPermissions.canAdministerPackedControlledDrugs ?? false,
    packedMedsDoNotNeedSecondCheck: groupPermissions.packedMedsDoNotNeedSecondCheck ?? false,
    canAdministerMedication: groupPermissions.canAdministerMedication ?? false,
    canAdministerMissedMedication: groupPermissions.canAdministerMissedMedication ?? false,
    canReAdministerMedication: groupPermissions.canReAdministerMedication ?? false,
    canAdministerS4Medication: groupPermissions.canAdministerS4Medication ?? false,
    canAdministerShortCourseMedication: groupPermissions. canAdministerShortCourseMedication ?? false,
    canAdministerPRNAndRecordItsOutcome: groupPermissions.canAdministerPRNAndRecordItsOutcome ?? false,
    canAdministerNIMAndRecordItsOutcome: groupPermissions.canAdministerNIMAndRecordItsOutcome ?? false,
    canAdministerNonPackedMedicationExcludingInjectable: groupPermissions.canAdministerNonPackedMedicationsExcludingInjectableMedication ?? false,
    canAdministerNonPackedMedicationIncludingInjectable: groupPermissions.canAdministerNonPackedMedicationsIncludingInjectableMedication ?? false,
    canAdministerSecondCheckMedication: groupPermissions.canAdministerDoubleSigningMedication ?? false,
    canAdministerPackedMedication: groupPermissions.canAdministerPackedMedications ?? false,
    canAdministerPatchMedicationExcludingS8: groupPermissions.canAdministerPatchMedicationExcludingS8 ?? false,
    canAdministerPatchMedicationIncludingS8: groupPermissions.canAdministerS8PatchMedication ?? false,
    canOperateSyringeDriver: groupPermissions.canOperateSyringeDriverMedication ?? false,
    canAdministerSlidingScaleMedication: groupPermissions.canAdministerSlidingScaleMedication ?? false,
    canVewResupplyOrders: groupPermissions.canViewResupplyOrder ?? false,
    canWithholdMedication: groupPermissions.canWithholdMedication ?? false,
    canAdministerPackedMedicationIndividually: groupPermissions.canAdministerPackedMedicationIndividually ?? false,
    canSetOtherDoseStatuses: groupPermissions.canSetOtherDoseStatuses ?? false,
    canSightPatch: groupPermissions.canSightPatch ?? false,
  };
  const apiUtils = useApiUtils();
  const services = useSyncCenter();
  const drugStore = useStore(services.drugs.store).store;
  const secondCheckSettingsStore = useStore(services.secondCheckSettings.store).store;

  const drugs = itiriri(drugStore.values()).toArray();

  const secondCheckSettings = secondCheckSettingsStore.get(facilityGroupId.toString());
  const roundScheduleContext = useRoundSchedule();
  React.useEffect(() => {
    if (!roundScheduleContext.roundSchedule) {
      roundScheduleContext.set(round);
    }
  }, [props.roundId]);
  const roundSchedule = round ? roundScheduleContext.roundSchedule : undefined;
  const packedPatientDay = apiUtils.patients.findPackedPatientDay(patient.hsId!, date, patient.facility);
  const previousPackedPatientDay = apiUtils.patients.findPackedPatientDay(
    patient.hsId!,
    subDays(date, 1),
    patient.facility,
  );

  const lastTwoPackedPatientDaysMedication = [
    ...(packedPatientDay?.packedMedications ?? []),
    ...(previousPackedPatientDay?.packedMedications ?? []),
  ];

  const interval = round
    ? apiUtils.rounds.getRoundWindow(round.createdAt ? DateUtils.toDate(round.createdAt) : new Date(), facilityGroupId)
    : undefined;

  const ceasedMedicationInARound = interval
    ? lastTwoPackedPatientDaysMedication.filter(
        (packedMed) =>
          packedMed.interimDeleted &&
          isWithinInterval(DateUtils.toDate(packedMed.doseTimestamp!), {
            start: DateUtils.toDate(interval.start),
            end: DateUtils.toDate(interval.end),
          }),
      )
    : [];

  const viewableMedication =
    roundSchedule && isToday(date)
      ? [
          ...roundSchedule.filter((s) => s.patient.hsId === patient.hsId).map((s) => s.packedMedication),
          ...ceasedMedicationInARound,
        ]
      : packedPatientDay?.packedMedications;

  // this is the list of drugs that appears on the resident details page
  const groupedMedications =
    viewableMedication &&
    apiUtils.residentDetails.getGroupedMedications(
      viewableMedication,
      patient.patientProfiles ?? [],
      date,
      props.patient.hsId!,
      props.patient.facility!,
    );

  useEffect(() => {
    const updatedSecondCheckMedicationList = apiUtils.residentDetails
      .getSecondCheckableMedication(
        facilityGroupId,
        patient,
        lastTwoPackedPatientDaysMedication,
        groupedMedications
          ?.filter((group) => group.scheduledActivityAction?.activity.patchActivity)
          ?.map((group) => group.scheduledActivityAction!.packedMed) ?? [],
        administrationPermissions.packedMedsDoNotNeedSecondCheck,
        roundSchedule,
      )
      .filter((med) => {
        return !secondCheckableMedication.find((oldMed) => oldMed.id === med.id);
      });

    setSecondCheckableMedication([...secondCheckableMedication, ...updatedSecondCheckMedicationList]);
  }, [roundSchedule, date]);

  const previousDosedDrugs = groupedMedications
    ? apiUtils.residentDetails.getPreviousDosedDrugsForPackedPatientMedications(
        groupedMedications?.flatMap((group) => [
          ...group.medications,
          ...(group.scheduledActivityAction?.packedMed ? [group.scheduledActivityAction.packedMed] : []),
        ]),
      )
    : [];

  const onAddOutcome = async (
    outcome: DrugOutcome,
    administeredDrugId: string,
    doseRoundClinicalSystemId: string,
    isAdHoc: boolean,
  ) => {
    if (!administrationPermissions.canAdministerPRNAndRecordItsOutcome) {
      toasts.error('You do not have permission to record PRN outcomes');
      return;
    }
    if (outcome.testResults.length > 0 && !administrationPermissions.canCreateTestResults) {
      toasts.error('You do not have permission to record vitals');
      return;
    }
    await DrugAdministrationUtils.addOutcome(
      outcome,
      administeredDrugId,
      doseRoundClinicalSystemId,
      services,
      user.profile.sub,
      patient,
      facilityGroupId,
      isAdHoc,
    );
  };

  const onAdministerDrug = async (
    drug: HSAdministeredDrug | HSAdministeredAdHocDrug,
    timestamp?: string,
    testResults?: HSTestResult[],
  ): Promise<EnqueuedDrugCreateData | undefined> => {
    if (!round && !administrationPermissions.canCompleteRound) {
      toasts.error('You do not have permission to complete a temporary round');
      return;
    }
    return await DrugAdministrationUtils.administerDrug(
      user.profile.sub,
      drug,
      patient,
      facilityGroupId,
      services.rounds.service,
      services.testResults.service,
      round,
      timestamp,
      testResults,
    );
  };

  const medicationListProps: MedicationListProps = {
    currentRound: round,
    patient: patient,
    roundSchedule: roundSchedule ?? [],
    facilityGroupId: facilityGroupId,
    previousDosedDrugs: previousDosedDrugs,
    drugList: drugs,
    administrationPermissions: administrationPermissions,
    openDrugDetail: openDrugDetail,
    selectedDate: props.date,
    secondCheckData: {
      secondCheckSettings: secondCheckSettings,
      secondCheckableMedication: secondCheckableMedication,
      setMedicationSecondChecked: (med) =>
        setSecondCheckableMedication(
          secondCheckableMedication.map((item) =>
            item.id === med.id ? { medication: item.medication, ...med } : item,
          ),
        ),
    },
  };

  return (
    <Switch>
      <Redirect exact path={path} to={urljoin(path, 'continuous')} />
      <MedicationListWrapper>
        <Route
          exact
          path={urljoin(path, 'prns')}
          render={() => (
            <PRNsMedicationList
              {...medicationListProps}
              groupedPackedMedicationList={groupedMedications!}
              onAddOutcome={onAddOutcome}
              onAdministerDrug={async (drug, doseTimestamp, testResults) =>
                onAdministerDrug(drug, doseTimestamp, testResults)
              }
            />
          )}
        />
        <Route
          exact
          path={urljoin(path, 'nims')}
          render={() => (
            <NIMsMedicationList
              {...medicationListProps}
              groupedPackedMedicationList={groupedMedications!}
              onAdministerAdHoc={async (drug) => onAdministerDrug(drug)}
              onAddOutcome={onAddOutcome}
            />
          )}
        />
        {groupedMedications && (
          <>
            <Route
              exact
              path={urljoin(path, 'continuous')}
              render={() => (
                <ContinuousMedicationList
                  {...medicationListProps}
                  onAdministerDrug={async (drug, timestamp, testResults) =>
                    onAdministerDrug(drug, timestamp, testResults)
                  }
                  groupedPackedMedicationList={groupedMedications}
                  patientId={patient.hsId!}
                  isToday={isToday(date)}
                  lastTwoPackedPatientDaysMedication={lastTwoPackedPatientDaysMedication}
                />
              )}
            />
            <Route
              exact
              path={urljoin(path, 'syringe-drivers')}
              render={() => (
                <SyringeDriversMedicationList
                  {...medicationListProps}
                  onAdministerDrug={async (drug, timestamp) => onAdministerDrug(drug, timestamp)}
                  groupedPackedMedicationList={groupedMedications}
                  lastTwoPackedPatientDaysMedication={lastTwoPackedPatientDaysMedication}
                  displayAllActions
                />
              )}
            />
            <Route
              exact
              path={urljoin(path, 'patches')}
              render={() => (
                <PatchesMedicationList
                  {...medicationListProps}
                  onAdministerDrug={async (drug, timestamp) => onAdministerDrug(drug, timestamp)}
                  groupedPackedMedicationList={groupedMedications}
                  patientId={patient.hsId!}
                  displayAllActions
                />
              )}
            />
            <Route
              exact
              path={urljoin(path, 'short-course')}
              render={() => (
                <ShortCoursesMedicationList
                  {...medicationListProps}
                  onAdministerDrug={async (drug, timestamp, testResults) =>
                    onAdministerDrug(drug, timestamp, testResults)
                  }
                  groupedPackedMedicationList={groupedMedications}
                />
              )}
            />
          </>
        )}
      </MedicationListWrapper>
    </Switch>
  );
}
interface PreviousDosedDrugs {
  mostRecentDosedDrugs: HSAdministeredDrug[];
  packedMedication: HSPackedMedication;
}

export interface MedicationListProps {
  currentRound?: HSDoseRound;
  patient: HSPatient;
  roundSchedule?: RoundScheduleItem[];
  facilityGroupId: number;
  previousDosedDrugs?: PreviousDosedDrugs[];
  drugList?: HSDrug[];
  administrationPermissions: AdministrationPermissions;
  secondCheckData?: SecondCheckData;
  openDrugDetail?: (props: IDrugDetailProps) => void;
  selectedDate: Date;
}

// TODO: what is the purpose of this?
export interface SecondCheckableMedication {
  medication: HSPackedMedication | HSPackedPrnMedication | NimAvailableDrugDto;
  id: number;
  hsId?: number;
  userName?: string;
  date?: Date;
}

interface SecondCheckData {
  secondCheckableMedication: SecondCheckableMedication[];
  secondCheckSettings?: ListSecondCheckSettingDto;
  setMedicationSecondChecked: (secondCheckableMedication: { id: number; date: Date; userName: string,  hsId: number | undefined}) => void;
}

const MedicationListWrapper = styled(Layout)`
  height: calc(100% - 10px);
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
