import React, { useState } from 'react';
import { IoPlay } from 'react-icons/io5';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import { HSAdministeredDose, HSDrug } from 'server-openapi';
import styled from 'styled-components';
import { useCurrentUser } from '../../core/authn/UserProvider';
import { DateUtils } from '../../core/utils/dateUtils';
import { toasts } from '../../kit/Toasts/Toaster';
import { useIsMounted } from "../../kit/hooks/useIsMounted";
import { DrugAdministrationUtils } from '../../pages/ResidentDetails/components/MedicationListsTabbedRouter/DrugAdministrationUtils';
import { useSyncCenter } from '../../syncstream/SyncCenterProvider';
import { SyncRounds } from '../../syncstream/SyncRounds';
import { useApiUtils } from '../../syncstream/utils/hooks/useApiUtils';
import { RoundScheduleItem, RoundUtils } from '../../syncstream/utils/RoundUtils';
import { BaseClock } from '../BaseClock/BaseClock';
import { Logger } from '../../core/logger/logger';
import { useStore } from '../../core/storage/hooks/UseStore';
import itiriri from 'itiriri';
import { User } from 'oidc-client';
import { ResidentDetailsUtils } from '../../syncstream/utils/ResidentDetailsUtils';
import { useHealthCheck } from '../../core/healthcheck/HealthCheckProvider';
import { FilterDrugTypeControlled, FilterDrugTypeOther } from '../../pages/Dashboard/DashboardPage/DashboardPage';
import { SyncTestResults } from '../../syncstream/SyncTestResults';
import { checkIfMedicationIsSelfAdministered } from '../../syncstream/utils/PackedPatientDayUtils';
import { useRoundSchedule } from '../../pages/Rounds/services/RoundScheduleProvider';
import {useGroupPermissions} from "../../core/authz/PermissionsProvider";
import {DrugUtils} from "../../syncstream/utils/DrugUtils";

// TODO: props interface
interface IProps {
  facilityGroupId: string;
  numberOfPatients: number;
  scheduleItems: RoundScheduleItem[];
  drugTypeControlled?: FilterDrugTypeControlled;
  drugTypeOther?: FilterDrugTypeOther;
  facilityIds: number[];
}

const ClockPositionContainer = styled.div`
  position: fixed;
  top: 50%;
  margin: 0 auto;
  transform: translateY(calc(-50% + 1rem));
`;

const StartRoundContainer = styled.div`
  background-color: ${(p) => p.theme.button.primary.bg};
  color: ${(p) => p.theme.button.primary.fg};
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.button.info.bg};
  }
`;

export function RoundClock(props: IProps) {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const services = useSyncCenter();
  const drugStore = useStore(services.drugs.store).store;
  const drugs = itiriri(drugStore.values()).toArray();
  const history = useHistory();
  const user = useCurrentUser();
  const roundUtils = useApiUtils().rounds;
  const roundScheduleContext = useRoundSchedule();
  const residentDetailsUtils = useApiUtils().residentDetails;
  const now = new Date();
  const groupPermissions = useGroupPermissions();
  const canUndertakeMedicationRound = groupPermissions.canUndertakeMedicationRound;
  const canCreateRound = groupPermissions.canCreateRounds;
  const isServerHealthy = useHealthCheck().isHealthy;

  const userRounds = roundUtils.activeUserRounds(user.profile.sub, parseInt(props.facilityGroupId));
  const userInActiveRound = userRounds.length > 0;
  const logger = new Logger('RoundClock');

  async function onClickStart() {
    try {
      if (!canUndertakeMedicationRound) {
        toasts.error('You do not have permission to undertake medication round.');
        return;
      }
      if (!canCreateRound) {
        toasts.error('You do not have permission to create round.');
        return;
      }
      if (!props.scheduleItems || props.scheduleItems.length === 0) {
        toasts.error('You must select at least one patient for the round.');
        return;
      }
      if (!isServerHealthy) {
        toasts.warning('You are offline. Actions in round will not be synced immediately.');
      }


      if (props.scheduleItems.some(scheduleItem => {
        const drug = scheduleItem.packedMedication.drugHsId
            ? drugStore.get(scheduleItem.packedMedication.drugHsId.toString())
            : undefined;
        if (!drug) {
          return false;
        }
        const medicationInformation = DrugUtils.getMedicationInformation(scheduleItem.packedMedication, drug);
        const secondCheckable = residentDetailsUtils.isMedicationSecondCheckable(+props.facilityGroupId, groupPermissions.packedMedsDoNotNeedSecondCheck ?? false, scheduleItem.packedMedication);
        return !roundUtils.canAdminister(groupPermissions, secondCheckable, medicationInformation);
      })) {
        toasts.warning('You do not have permission to administer some of the selected doses. Please select administrable drugs filter.');
        return;
      }


      const newRound = await startRound({
        user: user,
        facilityGroupId: props.facilityGroupId,
        facilityIds: props.facilityIds,
        drugList: drugs,
        scheduleItems: props.scheduleItems,
        residentDetailsUtils: residentDetailsUtils,
        drugType: props.drugTypeControlled,
        drugTypeOther: props.drugTypeOther,
        roundHasPatients: props.numberOfPatients !== 0,
        userInActiveRound: userInActiveRound,
        roundService: services.rounds.service,
        testResultsService: services.testResults.service,
        roundUtils: roundUtils,
      });
      roundScheduleContext.set(newRound);
      history.push(`/facility-group/${props.facilityGroupId}/rounds/${newRound.clinicalSystemId!}`);
    } catch (error) {
      logger.error('Error while starting round', error);
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }
  }
  const interval = roundUtils.getRoundWindow(now, parseInt(props.facilityGroupId));
  const dateInterval = DateUtils.intervalToDateInterval(interval);

  return (
    <ClockPositionContainer>
      <BaseClock interval={dateInterval}>
        <StartRoundContainer
          onClick={async () => {
            if (!loading) {
              setLoading(true);
              await onClickStart();
            }
          }}
          data-testid="start-round-button"
        >
          {!loading ? (
            <div style={{ textAlign: 'center' }}>
              <p style={{ fontSize: '5em', marginLeft: '0.1em' }}>
                <IoPlay />
              </p>
              <p style={{ fontWeight: 'bold' }}>START ROUND</p>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Loader type="Oval" color="#005555" height={80} width={80} />
            </div>
          )}
        </StartRoundContainer>
      </BaseClock>
    </ClockPositionContainer>
  );
}

interface IStartRound {
  user: User;
  facilityGroupId: string;
  facilityIds: number[];

  drugList: HSDrug[];
  residentDetailsUtils: ResidentDetailsUtils;
  drugType?: FilterDrugTypeControlled;
  drugTypeOther?: FilterDrugTypeOther;
  scheduleItems: RoundScheduleItem[];
  roundHasPatients: boolean;
  userInActiveRound: boolean;
  roundService: SyncRounds;
  testResultsService: SyncTestResults;
  roundUtils: RoundUtils;
}

async function startRound(props: IStartRound) {
  if (props.facilityIds.length === 0) {
    toasts.error('No facility selected.');
    throw new Error('No facility selected.');
  } else if (props.userInActiveRound) {
    toasts.error('You are already active in another round');
    throw new Error('User is already active in another round');
  } else if (!props.roundHasPatients) {
    toasts.error('Round has no patients');
    throw new Error('Round has no patients.');
  }
  const selfAdministeringScheduleItems = props.scheduleItems.filter(
    (item) => item.patient.selfAdministered || checkIfMedicationIsSelfAdministered(item.packedMedication, item.patient),
  );
  const schedule = props.scheduleItems.filter(
    (item, i, ar) =>
      ar.findIndex(
        (arItem) =>
          arItem.packedMedication.doseTimestamp === item.packedMedication.doseTimestamp &&
          arItem.patient.hsId === item.patient.hsId,
      ) === i,
  );
  const initialDoses: HSAdministeredDose[] = schedule.map((scheduleItem) => {
    return {
      facilityId: scheduleItem.patient.facility,
      patientId: scheduleItem.patient.hsId,
      doseTimestamp: !!scheduleItem.scheduledActivity
        ? DateUtils.fromDate(scheduleItem.scheduledActivity.time)
        : scheduleItem.packedMedication.doseTimestamp,
      createdAt: DateUtils.fromDate(new Date()),
      lastUpdatedAt: DateUtils.fromDate(new Date()),
    };
  });
  const round = await DrugAdministrationUtils.createRound(
    props.user.profile.sub,
    // Can only use 1 facility id, should always use first even if patients for 'any wing' filter are selected
    props.facilityIds[0],
    parseInt(props.facilityGroupId),
    props.roundService,
    props.drugType,
    props.drugTypeOther,
    initialDoses,
  );



  await props.roundUtils.selfAdministerSubRoutine({
    roundSchedule: selfAdministeringScheduleItems,
    drugList: props.drugList,
    user: props.user,
    residentDetailsUtils: props.residentDetailsUtils,
    facilityGroupId: props.facilityGroupId,
    syncRounds: props.roundService,
    syncTestResults: props.testResultsService,
    round: round,
  });
  return round;
}
