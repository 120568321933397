import React, {useState} from 'react';
import { Layout } from '../../../../kit/Layout';
import styled, { useTheme } from 'styled-components';
import { Grid } from '../../../../kit/Grid';
import { Button } from '../../../../kit/Button';
import { FormGroup } from '../../../../kit/Forms/FormGroup';
import { Form } from '../../../../kit/Forms/Form';
import { useForm } from 'react-hook-form';
import { SyncStreamAPI } from '../../../../syncstream/api';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { AxiosError } from 'axios';
import { RequirePermission } from '../../../../components/RequirePermission/RequirePermission';
import { RequireInternet } from '../../../../components/RequireInternet/RequireInternet';
import {useGlobalPermissions} from "../../../../core/authz/PermissionsProvider";
import {GlobalSettingsDto} from "server-openapi";
import {TextInput} from "../../../../kit/Forms/TextInput";
import {useAsync} from "../../../../kit/hooks/UseAsync";
import {apis} from "../../../../core/mrs/apis";
import {Loading} from "../../../../kit/Loading";
import {Checkbox} from "../../../../kit/Checkbox";

interface FormFields {
  supportEmail: string;
}

export function GlobalSettingsForm() {
    const globalPermission = useGlobalPermissions();
    return (
        <RequirePermission hasPermission={globalPermission.canOnboardFacilities}>
            <RequireInternet>
                <GlobalSettingsFormContent />
            </RequireInternet>
        </RequirePermission>
    );
}

function GlobalSettingsFormContent() {
    const globalSettingsApi = new SyncStreamAPI().globalSettings;
    const [globalSettings, setGlobalSettings] = useState<GlobalSettingsDto>();
    const theme = useTheme();
    const form = useForm<FormFields>();

    const asyncHook = useAsync(async () => {
        const data = (await apis.GlobalSettingsApi.globalSettingsShow())
            .data;
        setGlobalSettings(data);
    });

    async function onSubmit() {
        const updateGlobalSettingsReq: GlobalSettingsDto = {
            ...globalSettings
        };

        await globalSettingsApi.globalSettingsUpdate(updateGlobalSettingsReq).catch((error: AxiosError) => {
            toasts.error(error.response?.data.detail);
            throw error;
        });

        toasts.success('Global settings updated successfully');
        form.reset();
    }



    return (
        <>
            {asyncHook.loading || asyncHook.error ? (
                <Loading loading={asyncHook.loading} error={asyncHook.error} />
            ) : (
                <Layout gap={1}>
                    <HeaderContainer>
                        <HeaderText>Global Settings</HeaderText>
                    </HeaderContainer>
                    <SubTitleContainer>
                        <SubTitleText>Configure technical support email address.</SubTitleText>
                    </SubTitleContainer>
                    <Layout gap={1} padding={'1.25rem'} style={{ background: theme.backgrounds.lighter.bg, maxWidth: '50rem' }}>
                        <Form form={form} onSubmit={onSubmit}>
                            <Grid cols={1} gap={3}>
                                <FormGroup label="Support Email" fullWidth>
                                    <TextInput
                                        name="supportEmail"
                                        onChange={(_, value) =>
                                            setGlobalSettings({ ...globalSettings, supportEmail: value })
                                        }
                                        value={globalSettings?.supportEmail ?? undefined}
                                        fullWidth
                                        autoComplete="off"
                                    />
                                </FormGroup>
                                <FormGroup label="Syncs facilities to Healthstream" fullWidth>
                                    <Checkbox
                                        label="Synced to Healthstream"
                                        name="syncedToHealthstream"
                                        checked={globalSettings?.syncedToHs ?? false}
                                        onChange={() => {
                                            setGlobalSettings({
                                                ...globalSettings,
                                                syncedToHs: !globalSettings?.syncedToHs,
                                            })
                                        }}
                                        required
                                    />
                                </FormGroup>
                            </Grid>
                            <Button type="submit" style={{ justifySelf: 'flex-end', width: '250px' }}>Submit</Button>
                        </Form>
                    </Layout>
                </Layout>
            )}
        </>
    );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderText = styled.h2`
  margin: auto 0;
`;

const SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const SubTitleText = styled.p`
  margin: auto 0;
`;
